<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Quotation: #{{ quotation.jobNumber }}
          &nbsp;
          Customer ref: #{{ quotation.customerReference }}
          <span v-if="quotation.isRemedial">
            <span v-if="quotation.converted">(This remedial has been confirmed)</span>
          </span>
          <span v-else>
            <span v-if="quotation.converted">(This quotation has been converted)</span>
            <span v-if="quotation.lost">(This quotation has been lost)</span>
            <span v-if="quotation.proforma">(This quotation is awaiting a proforma payment)</span>
          </span>
          <span class="float-right">View</span>
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="quotation.lost">
      <v-col class="col-12 pt-0 pb-0">
        <router-link
          :to="`/quotations/preview/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        <v-btn
          small
          @click="duplicateQuotation"
          role="link"
          class="relative"
          style="top:-4px">
            Duplicate
        </v-btn>
        <v-btn
          small
          @click="cloneQuotation"
          role="link"
          class="relative"
          style="top:-4px">
            Clone
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="col-12 pt-0 pb-0">
        <span v-if="quotation.department">
          <router-link
            v-if="!quotation.approved"
            :to="`/quotations/add-lines/${quotation.id}`">
            <v-btn
              small
              class="relative"
              style="top:-4px">
                <span v-if="quotation.isRemedial">Change BOQs</span>
                <span v-else>Add BOQs</span>
            </v-btn>
          </router-link>
        </span>
        <span v-else>
          <v-btn
            @click="showMessage = true, message='You must set a department before you can add BOQs. Click Update button and set a department.'"
            small
            class="relative"
            style="top:-4px">
            Add BOQs
          </v-btn>
        </span>
        <router-link
          :to="`/quotations/add-documents/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
              Add documents
          </v-btn>
        </router-link>
        <router-link
          v-if="!quotation.approved"
          :to="`/quotations/update/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
              Update
          </v-btn>
        </router-link>
        <router-link
          :to="`/quotations/preview/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        <router-link
          v-if="!quotation.converted && !quotation.requiresAuth"
          :to="`/quotations/convert/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
            <span v-if="quotation.isRemedial === true">Confirm</span>
            <span v-else>Convert</span>
          </v-btn>
        </router-link>
        <v-btn
          small
          @click="authoriseQuotation"
          class="relative"
          style="top:-4px"
          v-if="!quotation.converted && quotation.requiresAuth && showAuthorise">
          Authorise
        </v-btn>
        <span v-if="userid === 107">
          <v-btn
            small
            class="relative"
            style="top:-4px"
            @click="resendToPal()"
            v-if="quotation.converted">Resend to PAL</v-btn>
        </span>
        <v-btn
          v-if="!quotation.proforma"
          small
          @click="makeProforma"
          class="relative"
          style="top:-4px">ProForma
        </v-btn>
        <v-btn
          v-if="quotation.approved === false"
          small
          @click="takeOverQuotation"
          role="link"
          class="relative"
          style="top:-4px">
            Take over
        </v-btn>
        <v-btn
          small
          v-if="!quotation.isRemedial"
          @click="duplicateQuotation"
          role="link"
          class="relative"
          style="top:-4px">
            Duplicate
        </v-btn>
        <v-btn
          small
          v-if="!quotation.isRemedial"
          @click="cloneQuotation"
          role="link"
          class="relative"
          style="top:-4px">
            Clone
        </v-btn>
        <v-btn
          small
          v-if="!quotation.isRemedial"
          @click="remedialQuotation"
          role="link"
          class="relative"
          style="top:-4px">
            Remedial
        </v-btn>
        <router-link v-if="quotation.converted"
          :to="`/live-jobs/view/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
              Job Card
          </v-btn>
        </router-link>
        <router-link
          v-if="!quotation.converted"
          :to="`/quotations/job-lost/${quotation.id}`">
          <v-btn
            small
            class="relative"
            style="top:-4px">
              Job Lost
          </v-btn>
        </router-link>
        <router-link custom v-slot="{ navigate }" :to="`/quotations/chase-history/${quotation.id}`">
          <v-btn
            class="relative"
            style="top: -4px"
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link">
            History
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-11">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <tbody>
              <tr>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Project Address</th>
                <th class="text-left">Value</th>
              </tr>
              <tr>
                <td>{{ quotation.customer }}</td>
                <td>{{ quotation.date | tinyDate }}</td>
                <td>{{ quotation.site }}</td>
                <td>
                  <span v-if="quotation.isRemedial">
                    &pound;0.00
                  </span>
                  <span v-else>
                    <span v-if="quotation.value > quotation.minChargeAmount">
                      {{ quotation.value | priceInPounds | currency }}
                    </span>
                    <span v-else>
                      {{ quotation.minChargeAmount | priceInPounds | currency }} (MinCharge)
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <th class="text-left">Contact</th>
                <th class="text-left">Contact Tel</th>
                <th class="text-left">Contact Email</th>
                <th class="text-left">Quoted by</th>
              </tr>
              <tr>
                <td>{{ quotation.contact }}</td>
                <td>{{ quotation.contactTel }}</td>
                <td>{{ quotation.contactEmail }}</td>
                <td>{{ quotation.quotedBy }}</td>
              </tr>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Distance (one way)</th>
                <th class="text-left">Estimated margin</th>
                <th class="text-left">Work days</th>
              </tr>
              <tr>
                <td><div v-html="quotation.description"></div></td>
                <td>{{ quotation.distance }}</td>
                <td>{{ quotation.est_margin }}</td>
                <td>{{ quotation.days }}</td>
              </tr>
              <tr>
                <th class="text-left">Notes</th>
                <th class="text-left">Drive Time</th>
                <th>Est fuel cost</th>
                <th class="text-left">Sector seven</th>
              </tr>
              <tr>
                <td><div v-html="quotation.notes"></div></td>
                <td>{{ quotation.travel_hours }}</td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on">
                        {{ (quotation.travelCost / 100000) | currency  }}<sup>*</sup>
                      </span>
                    </template>
                    <span>This cost is estimated based on 15mpg - an average of the current fleet fuel economy. It is for a round trip.</span>
                  </v-tooltip>
                </td>
                <td>{{ quotation.sectorSeven }}</td>
              </tr>
              <tr>
                <th class="text-left">Surface type</th>
                <th class="text-left">PO Number</th>
                <th>PO</th>
                <th class="text-left">Department</th>
              </tr>
              <tr>
                <td>{{ quotation.surfaceType }}</td>
                <td>{{ quotation.purchase_order }}</td>
                <td>
                  <a
                    v-if="quotation.purchase_order_uri"
                    :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${quotation.purchase_order_uri}`">
                    View
                  </a>
                </td>
                <td>
                  <span v-if="quotation.department">
                    <span v-if="quotation.department === 1">Paint</span>
                    <span v-if="quotation.department === 2">Thermo</span>
                    <span v-if="quotation.department === 3">Both</span>
                  </span>
                  <span v-else>None set</span>
                </td>
              </tr>
              <tr v-if="quotation.lost === true">
                <th class="text-left">Lost date</th>
                <th colspan="3" class="text-left">Lost reason</th>
              </tr>
              <tr v-if="quotation.lost === true">
                <td>{{ quotation.lostDate | prettyDate }}</td>
                <td colspan="3">{{ quotation.lostReason }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-10">
        <h4>Quotation lines</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-10">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width:20%" class="text-left">Boq</th>
                <th style="width:15%" class="text-left">Material</th>
                <th style="width:15%" class="text-left">Colour</th>
                <th style="width:10%" class="text-left">Cost</th>
                <th style="width:10%" class="text-left">Price</th>
                <th style="width:10%" class="text-left">Quantity</th>
                <th style="width:20%" class="text-left">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(quotationSection, index) in quotation.sections"
                :key="index">
                <td style="padding: 0" colspan="7">
                  <table style="width:100%">
                    <thead>
                      <tr>
                        <td style="width:100%" class="text-heavy" colspan="7">
                          Section title: {{ quotationSection.title }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(quotationLine, qlIndex) in quotationSection.lines" :key="qlIndex">
                        <td style="width:20%;padding: 0 16px">{{ quotationLine.boq }}</td>
                        <td style="width:15%;padding: 0 16px">{{ quotationLine.material }}</td>
                        <td style="width:15%;padding: 0 16px">{{ quotationLine.colour }}</td>
                        <td style="width:10%;padding: 0 16px">{{ quotationLine.cost | priceInPounds | currency }}</td>
                        <td style="width:10%;padding: 0 16px">{{ quotationLine.price | priceInPounds | currency }}</td>
                        <td style="width:10%;padding: 0 16px">{{ quotationLine.quantity }}</td>
                        <td
                          style="width:20%;padding: 0 16px">
                          {{ (quotationLine.quantity * quotationLine.price) | priceInPounds | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td style="width:80%" class="text-heavy" colspan="6">Section total</td>
                        <td style="width:20%;padding-left:16px;" class="text-heavy">
                        {{ quotationSection.total | priceInPounds | currency }}
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pt-5" v-if="quotation.documents">
      <v-col class="col-12">
        <h4>Documents attached to this quotation</h4>
      </v-col>
      <v-col
        class="col-12"
        v-for="(document, index) in quotation.documents"
        :key="index">
        <v-row>
          <v-col class="col-8">
            <a
              target="_blank"
              :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${document.uri}`">
              View:
              <span v-if="document.title">{{ document.title }}</span>
              <span v-else>{{ document.uri.substring(document.uri.indexOf("__") + 2) }}</span>
            </a>
          </v-col>
          <v-col class="col-2">
            <v-checkbox
              @change="updateForCrews(index)"
              class="mt-0 pt-0"
              v-model="document.forCrews"
              type="checkbox"
              label="Send to crews"></v-checkbox>
          </v-col>
          <v-col class="col-2">
            <v-btn x-small @click="deleteDocument(index)">
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="quoteTakenOver"
      max-width="500">
      <v-card>
        <v-card-title>
          You have taken over the quote {{ quotation.jobNumber }}
        </v-card-title>
        <v-card-text>
          The job number has been updated
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn @click="quoteTakenOver = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="requestProforma"
      max-width="500">
      <v-card>
        <v-card-text class="pt-5">
          You are about to request a pro-forma invoice for this quotation. Please confirm.
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red" text @click="requestProforma = false">Close</v-btn>
          <v-btn color="green" text @click="sendMakeProforma()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMessage"
      max-width="500">
      <v-card>
        <v-card-text class="pt-5">
          {{  message }}
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red" text @click="showMessage = false, message = ''">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ViewQuote',
  data() {
    return {
      authorisers: [],
      quotation: {},
      quoteTakenOver: false,
      requestProforma: false,
      showMessage: false,
      message: '',
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
    showAuthorise() {
      let showAuth = false;
      for (let i = 0; i < this.authorisers.length; i += 1) {
        if (this.authorisers[i].user_id === this.userid) showAuth = true;
      }
      return showAuth;
    },
  },
  methods: {
    authoriseQuotation() {
      const postData = {};
      postData.quotation = this.quotation;
      postData.user = this.userid;
      axios.post(`/quotations/authorise.json?token=${this.token}`, postData)
        .then(() => {
          this.quotation.requiresAuth = 0;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resendToPal() {
      axios.get(`/quotations/resendJobToPal/${this.$route.params.id}.json?token=${this.token}`);
    },
    makeProforma() {
      this.requestProforma = true;
    },
    sendMakeProforma() {
      axios.get(`/quotations/markProforma/${this.$route.params.id}/${this.userid}.json?token=${this.token}`)
      .then(() => {
        this.$router.push(`/quotations/index`);
      });
    },
    updateForCrews(index) {
      const document = this.quotation.documents[index];
      axios.get(`/quotationDocuments/toggleForCrews/${document.id}.json?token=${this.token}`);
    },
    deleteDocument(index) {
      const document = this.quotation.documents[index];
      axios.get(`/quotationDocuments/delete/${document.id}.json?token=${this.token}`);
      this.quotation.documents.splice(index, 1);
    },
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.quotation = response.data;
      });
    },
    takeOverQuotation() {
      axios.get(`/quotations/takeOver/${this.$route.params.id}/${this.userid}.json?token=${this.token}`)
      .then(() => {
        this.quotation = {};
        this.getQuotation();
        this.quoteTakenOver = true;
      });
    },
    duplicateQuotation() {
      axios.get(`/quotations/duplicate/${this.$route.params.id}/${this.userid}.json?token=${this.token}`)
      .then((response) => {
        this.$router.push(`/quotations/update/${response.data}`);
      });
    },
    cloneQuotation() {
      axios.get(`/quotations/clone/${this.$route.params.id}/${this.userid}.json?token=${this.token}`)
      .then((response) => {
        this.$router.push(`/quotations/update/${response.data}`);
      });
    },
    remedialQuotation() {
      axios.get(`/quotations/remedial/${this.$route.params.id}/${this.userid}.json?token=${this.token}`)
      .then((response) => {
        this.$router.push(`/quotations/update/${response.data}`);
      });
    },
    getAuthorisers() {
      axios.get(`/authorisers/getAll.json?token=${this.token}`)
        .then((response) => {
          this.authorisers = response.data.quoteAuthorisers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAuthorisers();
    this.getQuotation();
  },
}
</script>
